html,
body {
  font-family: "Lato", sans-serif;
  color: rgba(255, 255, 255, 0.9);
  background-color: #000000;
  user-select: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Oswald", sans-serif;
}
